
import React from 'react';
import { Image, Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { DefaultProps, PropTypes } from '../../../../core/Utils/SitecoreFields';

const SOC_Footer = ({ fields }) => {
  return (
    <div className={`component soc-footer ${fields['css classes']?.value}`}>
      <div className='container'>
        <div className='row g-0'>
          <div className='col-12 col-md-7'>
            <div className='row g-0'>
              <div className='col-12 col-md-3 d-flex flex-column justify-content-start'>
                <Image field={fields.Logo} className='img-fluid footer-logo' />
                <div><RichText field={fields['Logo text']} /></div>
              </div>
              <div className='col-12 col-md-9 d-flex flex-column justify-content-center info-message'>
                <div><RichText field={fields['Info message']} /></div>
                <hr />
                <div className='row g-0 d-flex flex-row justify-content-between'>
                  <div className='w-auto copyright'>
                    <RichText field={fields.Copyright} />
                  </div>
                  <div className='w-auto privacy'>
                    <Link field={fields.Privacy} />
                    <Link field={fields['Terms of use']} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>);
};

SOC_Footer.propTypes = PropTypes.SitecoreItem.inject(PropTypes.ContentBlock);
SOC_Footer.defaultProps = DefaultProps.SitecoreItem.inject(
  DefaultProps.ContentBlock,
);

export default SOC_Footer;
